
.markdown_container p {
    margin: 0;
    font-size: 0.9rem;
    /*color: #9B9B9B;*/
    /* line-height: 1.3; */
    letter-spacing: normal;
    /* font-family: sans-serif; */
    font-weight: 400;
    /* line-height: 1.3; */
}

.markdown_container h1 {
    margin: 0;
    font-size: 2.5rem;
    line-height: 1.3;
    letter-spacing: normal;
    /* font-family: serif; */
    font-weight: 700;
}

.markdown_container h2 {
    margin: 0;
    font-size: 1.6rem;
    line-height: 1.3;
    letter-spacing: normal;
    /* font-family: serif; */
    font-weight: 600;
}

.markdown_container h3 {
    margin: 0;
    font-size: 1.6rem;
    line-height: 1.3;
    letter-spacing: normal;
    /* font-family: serif; */
    font-weight: 600;
}

.markdown_container h4 {
    margin: 0;
    font-size: 1.3rem;
    line-height: 1.3;
    letter-spacing: normal;
    /* font-family: serif; */
    font-weight: 600;
}

.markdown_container h5 {
    margin: 0;
    font-size: 1.2rem;
    line-height: 1.3;
    letter-spacing: normal;
    /* font-family: serif; */
    font-weight: 600;
}

.markdown_container h6 {
    margin: 0;
    font-size: 1.1rem;
    line-height: 1.3;
    letter-spacing: normal;
    /* font-family: serif; */
    font-weight: 600;
}

.player{
  max-width: 100% !important;
}
